import * as React from "react";
import { PropsWithChildren } from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "gatsby";
import PageHelmet from "../../../components/common/helmet";
import PageLoading from "../../../components/common/pageLoading";
import { RouteComponentProps } from "@reach/router";
import useNovelApi from "../../../hooks/useNovelApi";

interface PropTypes {
  path: string;
  title?: string;
}

const NovelDetailPage: React.FC<
  PropsWithChildren<RouteComponentProps<PropTypes>>
> = ({ title }) => {
  const novel = useNovelApi.detail(title);

  if (!novel.data) return <PageLoading />;

  if (novel.error) {
    console.error(novel.error);
    return <h1>Error fetch data</h1>;
  }
  return (
    <>
      <PageHelmet title={novel.data.title} />
      <Container>
        <Card style={{ width: "100%" }}>
          <Card.Img variant="top" src={novel.data.img} />
          <Card.Body>
            <Card.Title>{novel.data.title}</Card.Title>
            <Card.Text>{novel.data.desc}</Card.Text>

            <div className="d-grid gap-2">
              <Link to="0" className="btn btn-primary">
                Read
              </Link>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default NovelDetailPage;
